.footer {
    width: 100vw;
    height: 45vh;
    background-color: #f4f5f5;
    padding-top: 40px;
}
.footer .footerBox {
    display: flex;
    justify-content: center;
    width: 100vw;
    min-height: fit-content;
    height: auto;
}
.footer .footerBox2 {
    width: 25vw;
    height: 70%;
}
.footer .logoBox {
    padding-top: 25px;
    width: 100%;
    height: 25%;
    font-family: 'Josefin Sans', sans-serif;
    display: flex;
    justify-content: center;
}
.footer .logoBox .logo {
    position: relative;
    letter-spacing: 2px;
    text-decoration: none;
    font-family: 'Josefin Sans', sans-serif;
    font-size: 45.5px;
    font-weight: 300;
    transition: 0.5s;
    color:  #009FD4;
    min-height: fit-content;
    height: auto;
}
.footer .logoBox .logo::after {
    content: "";
    position: absolute;
    background-color: #009FD4;
    height: 1px;
    width: 0%;
    left: 0%;
    bottom: 0px;
    transition: 0.2s;
}
.footer .logoBox .logo:hover::after {
    width: 100%;
}
.footer .socials {
    width: 100%;
    height: 25%;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    transform: translateY(2vw);
}
.footer .socials .socialsContainer {
    width: 25%;
    height: 100%;
    flex-wrap: wrap;
    min-width: fit-content;
    width: auto;
    min-height: fit-content;
    height: auto;
}
.footer .socials .socialsContainer a {
    font-family: 'Josefin Sans', sans-serif;
    font-size: 42px;
    color: black;
    transition: 0.5s;
}
.footer .socials .socialsContainer a:hover {
    font-size: 56px;
    color:  #009FD4;
}
.footer .copyright {
    transform: translateY(9vh);
    width: 100%;
}
.footer .copyright .para {
    text-align: center;
    font-family: 'Josefin Sans', sans-serif;
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 1.5px;
}
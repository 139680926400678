.outreach {
    overflow: hidden;
}
.outreach h1 {
    padding-top: 23vh;
    padding-bottom: 1vh;
    text-align: center;
    font-family: 'Josefin Sans', sans-serif;
    font-size: 60px;
    color: #484b49;
}
.allCards {
    display: block;
    padding-top: 20px;
    font-family: 'Josefin Sans', sans-serif;
    margin: auto;
}
.cardO {
    width: 1200px;
    min-height: 350px;
    height: auto;
    display: flex;
    margin-bottom: 20px;
    box-shadow: 0px 0px 4px #919392;
    margin-top: 30px;
    background-color: white;
    margin-left: auto;
    margin-right: auto;
}
.card2 {
    width: 1200px;
    min-height: 350px;
    height: auto;
    display: flex;
    justify-content: right;
    margin-bottom: 20px;
    box-shadow: 0px 0px 4px #919392;
    margin-top: 30px;
    background-color: white;
    margin-left: auto;
    margin-right: auto;
}
.descriptionBox {
    min-height: 350px;
    height: auto;
    width: 700px;
}
.descriptionBox h3 {
    padding-top: 20px;
    color: black;
    padding-left: 20px;
    font-size: 40px;
    font-weight: 300;
    word-break: break-word;
}
.descriptionBox p {
    padding-top: 20px;
    color: black;
    padding-left: 20px;
    font-size: 20px;
    font-weight: 300;
    word-break: break-word;
    padding-bottom: 20px;
}
.descriptionBoxR h3 {
    width: 100%;
    text-align: left;
    padding-top: 20px;
    color: black;
    font-size: 40px;
    font-weight: 300;
    margin-left: 10px;
    word-break: break-word;
}
.descriptionBoxR p {
    width: 100%;
    text-align: left;
    padding-top: 20px;
    color: black;
    font-size: 20px;
    font-weight: 300;
    word-break: break-word;
    padding-bottom: 20px;
    margin-left: 10px;
}
.descriptionBoxR {
    padding-right: 20px;
    min-height: 350px;
    height: auto;
    width: 700px;
}
.phone {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
.outreachContainer650 {
    border: 1px solid black;
    border-radius: 30px;
    width: 90%;
    min-height: fit-content;
    height: auto;
    display: block;
    margin-bottom: 40px;
}
.imageContainer650 {
    margin: 0 auto;
    width: 100%;
    min-height: fit-content;
    height: auto;
}
#outreachpara {
    text-align: center;
    word-break: break-word;
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 500;
    font-size: 20px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    padding-top: 20px;
}
h6 {
    text-align: center;
    word-break: break-word;
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 300;
    font-size: 35px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px
}
#outreachImage650 {
    display: block;
    width: 100%;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    margin-top: 0px;
}
.cardB {
    width: 1200px;
    min-height: 350px;
    height: auto;
    display: flex;
    margin-bottom: 20px;
    box-shadow: 0px 0px 4px #919392;
    margin-top: 30px;
    background-color: white;
    margin-left: auto;
    margin-right: auto;
}
.descriptionBoxBase {
    min-height: 350px;
    height: auto;
    width: 1180px;
}
.descriptionBoxBase h3 {
    padding-top: 20px;
    color: black;
    padding-left: 20px;
    font-size: 40px;
    font-weight: 300;
    word-break: break-word;
}
.descriptionBoxBase p {
    padding-top: 20px;
    color: black;
    padding-left: 20px;
    font-size: 20px;
    font-weight: 300;
    word-break: break-word;
    padding-bottom: 20px;
}
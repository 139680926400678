.members .memberHeader {
    padding-top: 23vh;
    padding-bottom: 1vh;
    text-align: center;
    font-family: 'Josefin Sans', sans-serif;
    font-size: 60px;
    color: #484b49;
}
.members .membersContainer {
    margin-top: 10vh;
    width: 70vw;
    display: flex;
    flex-wrap: wrap;
    margin-left: 15vw;
    justify-content: center;
}
.cards {
    position: relative;
    width: 260px;
    height: 400px;
    display: block;
    border-radius: 30px;
    font-family: 'Josefin Sans', sans-serif;
    background-color: #f7f6f6;
    margin-left: 10px;
    margin-right: 10px;
    padding-left: 20px;
    padding-right: 20px;
    transition: 0.5s;
    margin-bottom: 20px;
}
.cards:hover {
    background: black;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-blend-mode: darken;
}
.cards p {
    opacity: 0;
    transition: 0.2s;
    text-align: center;
    font-weight: 500;
    font-size: 20px;
    transform: translateY(-250px);
    word-wrap: break-word
}
.cards:hover p {
    color: white;
    text-align: center;
    font-weight: 500;
    font-size: 20px;
    transform: translateY(-250px);
    opacity: 1;
    word-wrap: break-word
}
.cards h1 {
    margin-top: 40px;
    text-align: center;
    color: #484b49;
    font-weight: 300;
    font-size: 25px;
    width: 100%;
}
.cards:hover h1,
.cards:hover .roleContainer em {
    opacity: 0;
    text-align: center;
    word-wrap: break-word
}

.cards .roleContainer {
    display: flex;
    justify-content: center;
}
.cards .roleContainer em {
    margin-top: 20px;
    color: #484b49;
    font-weight: 500;
    font-size: 25px;
    text-align: center;
    word-wrap: break-word
}
.cards .name {
    margin-top: 40px;
    text-align: center;
    color: #484b49;
    font-weight: 300;
    font-size: 25px;
    width: 100%;
}
.cards a {
    position: absolute;
    top: 300px;
    left: 0;
    display: block;
    margin-left: 25%;
    text-align: center;
    width: 50%;
    text-decoration: none;
    font-weight: 300;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 20px;
    color: white;
    background-color: #009FD4;
    border-radius: 40px;
    transition: 0.2s;
} 
.cards a:hover {
    background-color: #0088b5;
}
.cards:hover a {
    display: block;
    text-align: center;
    width: 50%;
    margin-left: 25%;
    text-decoration: none;
    font-weight: 300;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 20px;
    color: black;
    background-color: white;
    border-radius: 40px;
    transition: 0.2s;
}
.pictureContainer {
    height: 150px;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 100px;
    transition: 0.2s;
}
.descContainer {
    width: 100%;
    height: 100px;
}
.cards:hover .pictureContainer {
    opacity: 0;
}
.forms2 h2 {
    color: white;
    font-size: 50px;
    font-weight: 300;
    text-align: center;
    margin-bottom: -25px;
    word-wrap: break-word
}
.contact h1 {
    padding-top: 23vh;
    padding-bottom: 5vh;
    text-align: center;
    font-family: 'Josefin Sans', sans-serif;
    font-size: 60px;
    color: #484b49;
}
.contact .contactContainer {
    display: flex;
    min-height: fit-content;
    height: auto;
}
.contact .contactContainer .email {
    width: 50vw;
    height: 100%;
}
.contact .contactContainer .socials {
    width: 50vw;
    height: 100%;
    border-left: 0.1px solid #484b49;
}
.contact .contactContainer .socials h2,
.contact .contactContainer .email h2 {
    text-align: center;
    font-family: 'Josefin Sans', sans-serif;
    padding-top: 5vh;
    padding-bottom: 5vh;
    font-size: 60px;
    font-weight: 300;
    color: black;
}

.contact .slack h3 {
    text-align: center;
    font-family: 'Josefin Sans', sans-serif;
    padding-bottom: 10vh;
    font-size: 60px;
    font-weight: 300;
    color: black;
}
.emailContainer,
.contact .contactContainer .socialsContainer {
    padding-left: 12.5vw;
    padding-right: 12.5vw;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
.slackLogo {
    transform: translateY(28.5px);
    font-size: 100px;
    transition: 0.5s;
    color: purple;
}
.slackLogo:hover {
    transform: translateY(28.5px);
    font-size: 120px;
    color: purple;
}
.emailLogo,
.socialLogo {
    font-size: 100px;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
    transition: 0.5s;
    color: black;
}
.emailLogo:hover,
.socialLogo:hover {
    font-size: 120px;
    margin-left: 20px;
    color: #009FD4;
    cursor: pointer;
}
.Home {
    font-family: 'Josefin Sans', sans-serif;
}
.Home .event {
    margin-bottom: 80px;
    width: 100vw;
}
.Home .teamGlance h1,
.Home .aboutUs h1,
.Home .event h1 {
    text-align: center;
    color: #3d3c3d;
    padding-top: 4vh;
    font-size: 42px;
}
.Home .event p {
    text-align: center;
    color: #3d3c3d;
    margin-top: 4vh;
    font-weight: 300;
    font-size: 26.6px;
    letter-spacing: 0.5px;
}
.Home .event p .link {
    text-decoration: none;
    color:  #009FD4;
}
.Home .event .eventDates {
    min-height: fit-content;
    height: auto;
    display: flex;
    justify-content: center;
    width: 100%;
    height: calc(100% - 4vh - 4vh - 26.6px - 42px);
    flex-wrap: wrap;
}
.Home .event .eventDatesBlock {
    min-height: fit-content;
    height: auto;
    display: block;
    width: 100%;
    height: calc(100% - 4vh - 4vh - 26.6px - 42px);
    flex-wrap: wrap;
}
.Home .event .eventDates .eventContainer {
    width: 25vw;
    height: 100%;
    margin-left: 10px;
    margin-right: 10px;
}
.Home .event .eventDatesBlock .eventContainerBlock {
    width: 25vw;
    height: 100%;
    margin: 0 auto;
}
.Home .event .eventDates .eventContainer h2,
.Home .event .eventDatesBlock .eventContainerBlock h2 {
    padding-top: 10vh;
    text-align: center;
    font-weight: 300;
    font-size: 19.6px;
}
.Home .event .eventDates .eventContainer h3,
.Home .event .eventDatesBlock .eventContainerBlock h3 {
    text-align: center;
    font-weight: 300;
    font-size: 30px;
    margin-top: 20px;
    margin-bottom: -20px;
}
.Home .event .eventDates .eventContainer .changeText,
.Home .event .eventDatesBlock .eventContainerBlock .changeText {
    text-align: center;
    font-weight: 300;
    font-size: 20px;
}
.Home .aboutUs {
    width: 100vw;
}
.Home .aboutUs p {
    margin-left: 20%;
    width: 60%;
    text-align: center;
    color: #3d3c3d;
    margin-top: 4vh;
    font-weight: 300;
    font-size: 26.6px;
    letter-spacing: 0.5px;
}
.Home .hero {
    height: 100vh;
    width: 100vw;   
    background-image: linear-gradient(to right, #fff 0%, 70vw, #662d9100 100%);
}
.Home .hero .heroContainer {
    padding-top: 40vh;
    padding-left: 10vw;
    display: block
}
.Home .hero .heroContainer p {
    color:#3d3c3d;
    font-size: 20px;
    margin-bottom: 50px;
    margin-top: 20px;
}
.Home .hero .heroContainer a {
    text-decoration: none;
    color: white;
    font-size: 20px;
    padding-top: 18px;
    padding-bottom: 18px;
    padding-left: 30px;
    padding-right: 30px;
    border-radius: 30px;
    background-color:  #009FD4;
}
.Home .hero .heroContainer a:hover {
    background-color:  #0088b5;
}

.homepageTitle {
    text-align: center;
    font-weight: 300;
    font-size: 30px;
    margin-top: 20px;
    margin-bottom: -20px;
}
h4 {
    padding-top: 30px;
    text-align: center;
    font-weight: 300;
    font-size: 19.6px;
}
.Home .teamGlance {
    width: 100vw;
    min-height: fit-content;
    height: auto;
    margin-bottom: 30px;
}
.teamGlanceContainer {
    margin-top: 75px;
    width: 100%;
    height: 80%;
}
.team_container_1,
.team_container_2 {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
.teamGlanceText {
    padding-top: 125px;
    width: 400px;
    text-align: center;
}
.teamGlanceText p {
    font-weight: 300;
    font-size: 30px;
}
.Home .hero .heroContainer h2 {
    color:#3d3c3d;
    font-size: 70px;
    font-weight: 900;
    margin-right: 20px;
    color: #009FD4
}
.Home .hero .heroContainer span {
    color:#3d3c3d;
    font-size: 70px;
    font-weight: 900;
    display: inline-block;
    white-space: nowrap;
    word-wrap: break-word;
    max-width: 100%;
}
.animation {
    display: flex;
    min-width: fit-content;
    width: auto;
    display: flex;
    flex-wrap: wrap;
}
.Home .hero .heroContainer ul {
    overflow: hidden;
    height: 60px;
}

.Home .hero .heroContainer li {
    max-width: fit-content;
    list-style-type: none;
    top: 0;
    border-right: 2px solid #3d3c3d;
    position: relative;
    overflow: hidden;
    height: 60px;
    width: 0%;
    margin-bottom: 30px;
    animation:
        typing 2.5s steps(10) infinite,
        slide 12.5s steps(1) infinite;
}
@keyframes typing {
    40%,
    60% {
        width: 100%
    }
    100% {
        width: 0%
    }
}
@keyframes slide {
    20%, 30% {
        top: -90px;
    }
    40%, 50% {
        top: -180px;
    }
    60%, 70% {
        top: -270px;
    }
    80%, 90% {
        top: -360px;
    }
    100% {
        top: 0px;
    }
}

.imageSlideAnim {
    background-image: url('../../../../public/images/IMG_6646_1.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    width: 100vw;
    height: 100vh;
    left: 0vw;
    margin-bottom: 50px;
    position: absolute;
    top: 0;
    z-index: -5;
    animation: slide2 20s ease-in-out infinite;
}
@keyframes slide2 {
    0%,
    10% {
        left: 0vw;
    }
    15%,
    25% {
        left: -100vw
    }
    30%,
    40% {
        left: -200vw;
    }
    45%,
    55% {
        left: -300vw;
    }
    60%, 70% {
        left: -200vw;
    }
    75%, 85% {
        left: -100vw;
    }
    90%, 100% {
        left: 0vw;
    }
}
.imageSlideAnim2 {
    background-image: url('../../../../public/images/IMG_6696_1.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    width: 100vw;
    height: 100vh;
    left: 100vw;
    margin-bottom: 50px;
    position: absolute;
    top: 0;
    z-index: -7;
    animation: slide3 20s ease-in-out infinite;
}
@keyframes slide3 {
    0%,
    10% {
        left: 100vw;
    }
    15%,
    25% {
        left: 0vw
    }
    30%,
    40% {
        left: -100vw;
    }
    45%,
    55% {
        left: -200vw;
    }
    60%, 70% {
        left: -100vw;
    }
    75%, 85% {
        left: 0vw;
    }
    90%, 100% {
        left: 100vw;
    }
}

.imageSlideAnim3 {
    background-image: url('../../../../public/images/5F7BF985-384B-48EE-B752-78A057A1C51A.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    width: 100vw;
    height: 100vh;
    left: 200vw;
    margin-bottom: 50px;
    position: absolute;
    top: 0;
    z-index: -9;
    animation: slide4 20s ease-in-out infinite;
}
@keyframes slide4 {
    0%,
    10% {
        left: 200vw;
    }
    15%,
    25% {
        left: 100vw
    }
    30%,
    40% {
        left: 0vw;
    }
    45%,
    55% {
        left: -100vw;
    }
    60%, 70% {
        left: 0vw;
    }
    75%, 85% {
        left: 100vw;
    }
    90%, 100% {
        left: 200vw;
    }
}

.imageSlideAnim4 {
    background-image: url('../../../../public/images/IMG_9438.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    width: 100vw;
    height: 100vh;
    left: 300vw;
    margin-bottom: 50px;
    position: absolute;
    top: 0;
    z-index: -9;
    animation: slide5 20s ease-in-out infinite;
}
@keyframes slide5 {
    0%,
    10% {
        left: 300vw;
    }
    15%,
    25% {
        left: 200vw
    }
    30%,
    40% {
        left: 100vw;
    }
    45%,
    55% {
        left: 0vw;
    }
    60%, 70% {
        left: 100vw;
    }
    75%, 85% {
        left: 200vw;
    }
    90%, 100% {
        left: 300vw;
    }
}
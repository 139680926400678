.join h1 {
    padding-top: 23vh;
    padding-bottom: 1vh;
    text-align: center;
    font-family: 'Josefin Sans', sans-serif;
    font-size: 60px;
    color: #484b49;
}
.join h2 {
    text-align: center;
    font-family: 'Josefin Sans', sans-serif;
    font-size: 50px;
    color: black;
}
.join .content {
    text-align: center;
    font-family: 'Josefin Sans', sans-serif;
    font-size: 22px;
    font-weight: 300;
    color: black;
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
}
.join .joinContent {
    margin-top: 70px;
    font-family: 'Josefin Sans', sans-serif;
    font-size: 18px;
    line-height: 25px;
    color: black;
    padding-left: 250px;
    padding-right: 250px;
}
.QandA {
    padding-left: 200px;
    padding-right: 250px;
}

.QandA650 {
    /* padding-left: 200px; */
    /* padding-right: 250px; */
    /* width: 90vw; */
    display: flex;
    justify-content: center;
}
.QandAcontainer {
    box-shadow: 0 0.125rem 0.375rem 0 rgba(0,0,0,.12);
    font-family: Josefin Sans,sans-serif;
    min-height: fit-content;
    height: auto;
    padding-top: 30px;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
}
.QandAcontainer650 {
    box-shadow: 0 0.125rem 0.375rem 0 rgba(0,0,0,.12);
    font-family: Josefin Sans,sans-serif;
    min-height: fit-content;
    height: auto;
    padding-top: 30px;
    /* width: 100%; */
    width: 90vw;
}
.FAQ {
    text-align: center;
    font-family: 'Josefin Sans', sans-serif;
    font-size: 50px;
    color: black;
}
.Question {
    font-family: 'Josefin Sans', sans-serif;
    font-size: 25px;
    font-weight: 300;
    color: black;
}
.Answer {
    font-family: 'Josefin Sans', sans-serif;
    font-size: 25px;
    font-weight: 300;
    color: black;
}

.Question650 {
    font-size: 25px;
    font-weight: 300;
    margin-left: 30px;
    color: black;
    font-family: Josefin Sans,sans-serif;
    margin-left: 30px;
    margin-right: 30px;
}
.Answer650 {
    font-size: 25px;
    font-weight: 300;
    margin-left: 30px;
    color: black;
    font-family: Josefin Sans,sans-serif;
}
@font-face {
    font-family: nulshock;
    src: url(./../../../public/fonts/Nulshock\ Bd.otf);
}
.navbar {
    height: 10vh;
    width: 100vw;
    display: flex;
    justify-content: space-between;
    background-color: transparent;
    position: absolute;
    top: 0;
    left: 0;
}
.navbar .right {
    padding-top: calc(5vh - 7px);
}
.navbar .right .navlink {
    position: relative;
    text-decoration: none;
    margin-right: 25px;
    font-size: 15px;
    font-weight: 900;
    color: #484b49;
    font-family: nulshock, 'Josefin Sans', sans-serif;
}
.navbar .right .navlink:hover {
    font-size: 120%;
    transition: 0.3s;
}
.navbar .right .navlink::after {
    content: "";
    position: absolute;
    background-color:  #009FD4;
    height: 1px;
    width: 0%;
    left: 0;
    bottom: -1px;
    transition: 0.2s;
}
.navbar .right .navlink:hover::after {
    width: 100%;
}

.navbar .right #fill {
    text-decoration: none;
    margin-right: 30.5px;
    background-color:  #009FD4;
    padding-top: 17.5px;
    padding-bottom: 17.5px;
    padding-left: 30.5px;
    padding-right: 30.5px;
    border-radius: 30px;
    font-size: 15px;
    font-weight: 900;
    color: white;
    font-family: nulshock, 'Josefin Sans', sans-serif;
    transition: 0.2s;
}
.navbar .right #fill::after {
    height: 0px;
}
.navbar .right #fill:hover { 
    background-color: #0088b5;
}

.left {
    margin-top: 20px;
    margin-left: 40px;
    padding-top: calc(5vh - 15px);
    width: 100px;
    height: 14px;
    background: url('../../../public//images/IRIS-03.png');
    background-size: contain;
    background-repeat: no-repeat;
}

.navbar2 {
    height: 10vh;
    width: 100vw;
    display: flex;
    justify-content: space-between;
    background-color: transparent;
    position: absolute;
    top: 0;
    left: 0;
}
.navbar2 .menu {
    color: #3d3c3d;
    transform: translate(-50px, 20px);
    font-size: 30px;
    cursor: pointer;
    z-index: 6;
}
.navbar3 {
    height: 10vh;
    width: 100vw;
    display: flex;
    justify-content: right;
    background-color: transparent;
    position: absolute;
    top: 0;
    left: 0;
}
.navbar3 .menuFixed {
    color: #3d3c3d;
    font-size: 30px;
    cursor: pointer;
    z-index: 6;
    position: fixed;
    transform: translate(-50px, 20px);
}
.menubarOpen {
    position: absolute;
    top: 0vh;
    height: 100vh;
    background-color: white;
    width: 100%;
    z-index: 3;
    position: fixed;
    padding-top: 70px;
}
.menubarClose {
    position: absolute;
    top: -100vh;
    height: 100vh;
    background-color: white;
    width: 100%;
    z-index: 3;
    position: fixed;
}
.menubarOpen .navlink {
    text-decoration: none;
    font-family: nulshock, 'Josefin Sans', sans-serif;
    color: #009FD4;
    font-size: 400%;
    text-align: center;
    display: block;
    transition: 0.2s;
}

.menubarOpen .navlink:hover {
    font-size: 80px;
}